const post_number = "291";  
const entrees = [
    {name: "Hamburger", price: 6.00},
    {name: "Bacon Cheesburger", price: 6.50},
    {name: "Bacon Cheesburger", price:7},
    {name: "Patty Melt", price:6},
    {name: "Club Sandwich", price:7.5},
    {name: "Hot or Cold Sub", price:7.5},
    {name: "BLT", price:6},
    {name: "Grilled Cheese", price:3.5},
    {name: "Grilled Cheese & Bacon", price:5},
    {name: "Grilled Ham & Cheese", price:5},
    {name: "Chicken Strip Sandwich", price:6},
    {name: "Chicken Strip Basket", price:7.5}
];

const sides = [
    {name: "French Fries", price:3},
    {name: "Onion Rings", price:4},
    {name: "Chili Cheese Fries", price:4},
    {name: "Fried Mushrooms", price:5},
    {name: "Chips", price:1}
];

const dinner = [
    {name: "Corn Dog", price:3},
    {name: "10 pc. Wings w/ Fries (Sweet Heat, Teriyakia, Buffalo)", price:12},
    {name: "1/4 lb. Burger w/ Fries", price:7},
    {name: "Chicken Strips w/ Fries", price:8},
    {name: "French Fries", price:3}
];

export {post_number, entrees, sides, dinner};